import React from 'react'
import styled from '@emotion/styled'
import LogoSvg from '../../../static/images/logo_footer.svg'

const StyledSvg = styled(LogoSvg)`
  text-indent: -9999px;
  margin: 1rem 0;
`

const Logo = () => {
  return <StyledSvg />
}

export default Logo
