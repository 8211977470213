import React from 'react'
import styled from '@emotion/styled'
import Logo from './Logo'

const Wrapper = styled.footer`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.colors.text};
  padding-bottom: 1em;
`
const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 1em 0 1.5em 0;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.text};
`

const Item = styled.li`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0.5em 3em;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 600;
    transition: all 0.2s;
    color: ${props => props.theme.colors.background};
    &:hover {
      color: ${props => props.theme.colors.yellow};
    }
    &:visited {
      color: ${props => props.theme.colors.background};
    }
  }
`
const FooterText = styled.span`
  color: ${props => props.theme.colors.background};
  letter-spacing: 0;
  font-size: 14px;
  line-height: 1.25em;
  text-align: center;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
`

const Footer = () => (
  <Wrapper>
    <List>
      <Item>
        <Logo />
      </Item>
      <Item>
        <FooterText>
          © {new Date().getFullYear()} Northern California Joyful Church{' '}
          <div style={{ display: 'inline-block' }}>All Rights Reserved.</div>
        </FooterText>
      </Item>
    </List>
  </Wrapper>
)

export default Footer
